import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import { graphql } from 'gatsby';
import { mapPageData } from '../utils/Mappers';
import useModules from '../utils/useModules';


const IndexPage = ({ data={} }) => {

  const { modules, title, id } = mapPageData(data.page)

  const [modulesToRender, heroModule ] = useModules(modules, { getHeroModule: true })

  return (
  <>
  <SEO title={title} />
  <Layout 
    arrow={true}
    className="index-page "     
    heroModule={heroModule}
>
      {/* Content goes here */}

      <div className="space-y-24 ">
      { modulesToRender.map( ({Module, id}) => {
        return <Module key={id} />
      }  )} 
      </div>
   
  </Layout>
  </>
)}

export default IndexPage


export const IndexPageQuery = graphql`
  query HomePageQuery {
  page: contentfulPage(pageId: {eq: "homepage"}) {
      ...PageFragment
    }
  }
`
